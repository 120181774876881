// 发布状态枚举
export const PublishStatusEnum = {
  NOT_PUBLISH: 0, // 未发布
  ALREADY_PUBLISH: 1, // 已发布
  CANCEL_PUBLISH: 2, // 取消发布
};

// 发布状态文案枚举
export const PublishStatusTextEnum = {
  0: '待发布',
  1: '已发布',
  2: '取消发布',
};

// 发布状态选项
export const publishStatusOptions = [
  {
    label: PublishStatusTextEnum[PublishStatusEnum.NOT_PUBLISH],
    value: PublishStatusEnum.NOT_PUBLISH,
  },
  {
    label: PublishStatusTextEnum[PublishStatusEnum.ALREADY_PUBLISH],
    value: PublishStatusEnum.ALREADY_PUBLISH,
  },
  {
    label: PublishStatusTextEnum[PublishStatusEnum.CANCEL_PUBLISH],
    value: PublishStatusEnum.CANCEL_PUBLISH,
  },
];

// 活动状态枚举
export const ActivityStatusEnum = {
  NOT_START: 0, // 未开始
  UNDERWAY: 1, // 进行中
  FINISH: 2, // 已结束
};

// 活动状态文案枚举
export const ActivityStatusTextEnum = {
  0: '未开始',
  1: '进行中',
  2: '已结束',
};

// 活动状态选项
export const activityStatusOptions = [
  {
    label: ActivityStatusTextEnum[ActivityStatusEnum.NOT_START],
    value: ActivityStatusEnum.NOT_START,
  },
  {
    label: ActivityStatusTextEnum[ActivityStatusEnum.UNDERWAY],
    value: ActivityStatusEnum.UNDERWAY,
  },
  {
    label: ActivityStatusTextEnum[ActivityStatusEnum.FINISH],
    value: ActivityStatusEnum.FINISH,
  },
];

// 报名状态枚举
export const ApplyStatusEnum = {
  YES: 0, // 需要报名
  NO: 1 // 无需报名
}

// 签到方式枚举
export const ParticipationWayEnum = {
  OFFLINE: 0, // 线下签到
  NOT: 1, // 无需签到
  ONLINE: 2 // 线上签到
}

// 操作类型枚举
export const ListOperationTypeEnum = {
  PUBLISH: 1, // 发布
  CANCEL_PUBLISH: 2, // 取消发布
  DETAIL: 3, // 查看
  EDIT: 4, // 编辑
  EXPORT_APPLY_INFO: 5, // 导出报名信息
  EXPORT_ONLINE_PARTICIPATION_INFO: 6, // 导出线上签到信息
  REUSE: 7, // 信息复用
}