<template>
  <div>
    <ds-header title="周期活动列表"></ds-header>
    <a-form layout="inline" style="margin-bottom: 10px">
      <a-form-item label="发布状态：">
        <a-select style="width: 200px" placeholder="请选择发布状态" v-model="searchForm.publishStatus"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option :value="item.value" v-for="item in publishStatusOptions" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="活动状态：">
        <a-select style="width: 200px" placeholder="请选择活动状态" v-model="searchForm.activityStatus"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option :value="item.value" v-for="item in activityStatusOptions" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px" icon="search"> 查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm" icon="redo">重置</a-button>
      </span>
      <br />
      <div style="margin-top: 5px">
        <a-button type="primary" @click="addActive" icon="plus"> 新建活动 </a-button>
      </div>
    </a-form>
    <div style="margin-top: 10px">
      <!-- :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" -->
      <a-table :rowKey="(record, index) => record.id" :columns="columns" :data-source="tableData"
        :pagination="pagination.total ? pagination : false" @change="pageChange" bordered>
        <span slot="action" slot-scope="text, record">
          <a-button type="link" @click="check(record, ListOperationTypeEnum.PUBLISH)" class="btn"
            v-if="record.publishStatus === PublishStatusEnum.NOT_PUBLISH">发布</a-button>
          <a-button type="link" @click="check(record, ListOperationTypeEnum.CANCEL_PUBLISH)" class="btn"
            v-if="record.publishStatus === PublishStatusEnum.ALREADY_PUBLISH && (record.activityStatus === ActivityStatusEnum.NOT_START || record.activityStatus === ActivityStatusEnum.UNDERWAY)">取消发布</a-button>
          <a-button type="link" @click="check(record, ListOperationTypeEnum.DETAIL)" class="btn">查看</a-button>
          <a-button type="link" @click="check(record, ListOperationTypeEnum.EDIT)" class="btn"
            v-if="record.publishStatus === PublishStatusEnum.NOT_PUBLISH && record.activityStatus === ActivityStatusEnum.NOT_START">编辑</a-button>
          <a-button type="link" @click="check(record, ListOperationTypeEnum.EXPORT_APPLY_INFO)" class="btn"
            v-if="record.isApply === ApplyStatusEnum.YES">导出报名信息</a-button>
          <a-button type="link" @click="check(record, ListOperationTypeEnum.EXPORT_ONLINE_PARTICIPATION_INFO)"
            class="btn" v-if="record.participationWay === ParticipationWayEnum.ONLINE">导出线上签到信息</a-button>
          <a-button type="link" @click="check(record, ListOperationTypeEnum.REUSE)" class="btn">信息复用</a-button>
        </span>
      </a-table>
    </div>
    <!-- 导出报名信息 -->
    <a-modal title="选择导出文件" :visible="exportEntryVisible" @ok="downEntryFile" @cancel="exportEntryVisible = false">
      <div class="exportEntry">
        <a-checkbox-group v-model="plainVal">
          <a-checkbox v-for="(item, index) in plainOptions" :value="item.value" :key="index">{{
            item.label
            }}</a-checkbox>
        </a-checkbox-group>
      </div>
    </a-modal>
    <!-- 发布取消发布对话框 -->
    <a-modal :title="title" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
      <p v-if="publishStatus === '1'" style="font-weight: 600;text-align: center;">活动发布后，用户将可查看该活动，确定要发布此活动吗？</p>
      <p v-if="publishStatus === '2'" style="font-weight: 600;text-align: center;">
        活动取消发布后，用户将无法查看该活动，且活动无法再重新发布，确定要发布此活动吗？</p>
    </a-modal>
    <!-- 发布取消发布对话框 -->
    <a-modal title="取消发布" :bodyStyle="{ width: '800px' }" :visible="visible2" :confirm-loading="confirmLoading"
      @ok="handleOk2" @cancel="handleCancel2">
      <p>该活动已有用户报名，若要取消发布，请填写‘活动取消系统提示’！</p>
      <div style="display: flex;">
        <span style="color:red;margin-right: 5px;">*</span>
        <a-textarea v-model="cancelReason" placeholder="请输入活动取消系统提示内容" :maxLength="50"
          :auto-size="{ minRows: 3, maxRows: 5 }" />
      </div>
      <p style="text-align: right;">{{ cancelReason.length }}/50</p>
      <p>
        注意：点击确认后，您填写的‘活动取消系统提示’将会被系统推送给已报名用户，且已取消发布的活动无法再重新发布，请确认后再点击确定！
      </p>
    </a-modal>
  </div>
</template>

<script>
import * as api from '@/api/enterpriseServiceActivitiesManagement/index.js';
import { PublishStatusTextEnum, ActivityStatusTextEnum, publishStatusOptions, PublishStatusEnum, activityStatusOptions, ActivityStatusEnum, ApplyStatusEnum, ParticipationWayEnum, ListOperationTypeEnum } from './help.js';
import moment from 'moment'

export default {
  data() {
    return {
      searchForm: {},
      publishStatusOptions,
      activityStatusOptions,
      PublishStatusEnum,
      ActivityStatusEnum,
      ApplyStatusEnum,
      ParticipationWayEnum,
      ListOperationTypeEnum,
      tableData: [],
      // selectedRowKeys: [],
      columns: [
        {
          title: '序号',
          key: 'index',
          customRender: (text, row, index) => {
            return index + 1;
          },
        },
        {
          title: '活动标题',
          dataIndex: 'activityTitle',
          key: 'activityTitle',
        },
        {
          title: '活动时间',
          key: 'startTime',
          customRender: (text, row, index) => {
            return row.startTime + '至' + row.endTime;
          },
        },
        {
          title: '活动状态',
          dataIndex: 'activityStatus',
          key: 'activityStatus',
          customRender: (text, row, index) => {
            return ActivityStatusTextEnum[row.activityStatus] || '/';
          },
        },
        {
          title: '当前报名人数',
          dataIndex: 'counts',
          key: 'counts',
        },
        {
          title: '发布状态',
          dataIndex: 'publishStatus',
          key: 'publishStatus',
          customRender: (text, row, index) => {
            return PublishStatusTextEnum[row.publishStatus] || '/';
          },
        },
        {
          title: '操作',
          key: 'action',
          width: 300,
          scopedSlots: { customRender: 'action' },
        },
      ],
      exportEntryVisible: false,
      plainVal: [],
      plainOptions: [],
      pagination: {
        pageSizeOptions: ['10', '20', '30', '50', '100'],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
      exportType: undefined,
      visible: false, // 显示发布/取消发布弹窗
      visible2: false, // 显示发布/取消发布弹窗(其他情况)
      activityId: '', // 活动id
      publishStatus: '', // 发布状态
      confirmLoading: false,
      cancelReason: '', // 取消原因
      title: '',
    };
  },
  mounted() {
    this.getActiveData();
  },
  methods: {
    getActiveData() {
      let obj = {
        parentActivityId: this.$route.query.periodActivityId,
        publishStatus: this.searchForm.publishStatus,
        activityStatus: this.searchForm.activityStatus,
        page: this.pagination.current - 1,
        size: this.pagination.pageSize,
      };
      api.getActivityList(obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.data;
          this.pagination.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    resetSearchForm() {
      this.searchForm = {
        publishStatus: '',
        activityStatus: '',
      };
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getActiveData();
    },
    search() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getActiveData();
    },
    pageChange(option) {
      this.pagination.current = option.current;
      this.pagination.pageSize = option.pageSize;
      this.getActiveData();
    },
    // // 选中的
    // onSelectChange(selectedRowKeys) {
    //   this.selectedRowKeys = selectedRowKeys;
    // },
    // 下载名单
    async downEntryFile() {
      if (!this.plainVal.length) {
        this.$message.warning('请选择时间段');
        return
      }
      this.plainOptions.forEach(item => {
        console.log(item);

        this.plainVal.forEach(element => {
          if (element == item.value) {
            this.downFile(item.activityId, item.activityTitle, element)
          }
        })
      })
    },
    //新建活动
    addActive() {
      this.$router.push({
        path: '../../enterpriseServiceActivitiesManagement/add',
        query: {
          periodActivityTitle: this.$route.query.periodActivityTitle,
          periodActivityId: this.$route.query.periodActivityId
        },
      });
    },
    check(record, operationType) {
      if (operationType === ListOperationTypeEnum.PUBLISH || operationType === ListOperationTypeEnum.CANCEL_PUBLISH) {
        this.checkFfPublishingOrUnpublishingIsAllowed(record, operationType);
      } else if (operationType === ListOperationTypeEnum.DETAIL) {
        this.$router.push({
          path: "/enterpriseServiceActivitiesManagement/detail",
          query: {
            id: record.activityId,
          },
        });
      } else if (operationType === ListOperationTypeEnum.EDIT) {
        this.$router.push({
          path: "/enterpriseServiceActivitiesManagement/add",
          query: {
            id: record.activityId,
            periodActivityTitle: this.$route.query.periodActivityTitle,
            periodActivityId: this.$route.query.periodActivityId
          },
        });
      } else if (operationType === ListOperationTypeEnum.EXPORT_APPLY_INFO) {
        this.exportEntryBut(record, 1);
      } else if (operationType === ListOperationTypeEnum.EXPORT_ONLINE_PARTICIPATION_INFO) {
        this.exportEntryBut(record, 2);
      } else if (operationType === ListOperationTypeEnum.REUSE) {
        this.$router.push({
          path: "/enterpriseServiceActivitiesManagement/add",
          query: {
            id: record.activityId,
            reuseType: 1,
            periodActivityTitle: this.$route.query.periodActivityTitle,
            periodActivityId: this.$route.query.periodActivityId
          },
        });
      }
    },
    //导出
    exportEntryBut(data, type) {
      this.exportType = type
      this.plainOptions = []
      this.plainVal = []
      if (data.activityPeriods) {
        data.activityPeriods.forEach(element => {
          let str = moment(element.startTime).format('YYYY/MM/DD HH:mm') + ' - ' + moment(element.endTime).format('YYYY/MM/DD HH:mm')
          let obj = { label: str, value: str, id: element.id, activityTitle: data.activityTitle, disabled: moment().valueOf() < moment(element.startTime).valueOf(), activityId: data.activityId }
          this.plainOptions.push(obj)
        })
      } else {
        let str = moment(data.startTime).format('YYYY/MM/DD HH:mm') + ' - ' + moment(data.endTime).format('YYYY/MM/DD HH:mm')
        this.plainOptions = [{ label: str, value: str, id: data.activityId, activityTitle: data.activityTitle, disabled: moment().valueOf() < moment(data.startTime).valueOf(), activityId: data.activityId }]
      }

      this.exportEntryVisible = true
    },
    // 检验是否能取消发布/发布
    async checkFfPublishingOrUnpublishingIsAllowed(record, operationType) {
      const publishStatus = operationType === ListOperationTypeEnum.PUBLISH ? '1' : '2';
      let res = await api.GET_LIST_PUBLISH({ activityId: record.activityId, publishStatus });
      if (res.code === this.$SUCCESS_CODE) {
        if (operationType === ListOperationTypeEnum.PUBLISH) {
          this.title = '发布'
        } else {
          this.title = '取消发布'
        }
        this.visible = true
        this.activityId = record.activityId;
        this.publishStatus = publishStatus
      } else if (res.code === '1000') {
        this.activityId = record.activityId;
        this.visible2 = true
      } else {
        this.$message.error(res.msg);
      }
    },
    handleOk(e) {
      this.confirmLoading = true;
      this.setListPublish(this.activityId, this.publishStatus)
    },
    handleCancel(e) {
      console.log('Clicked cancel button');
      this.visible = false;
    },
    handleOk2(e) {
      if (!this.cancelReason) {
        this.$message.warning('取消原因不能为空');
        return
      }
      this.confirmLoading = true;
      this.setListPublish(this.activityId, 2)
    },
    handleCancel2(e) {
      console.log('Clicked cancel button');
      this.visible2 = false;
    },
    // 发布/取消发布
    async setListPublish(activityId, val) {
      try {
        const res = await api.SET_LIST_PUBLISH({ activityId: activityId, publishStatus: val, cancelReason: this.cancelReason })
        if (res.code === this.$SUCCESS_CODE) {
          this.getActiveData();
          this.visible = false
          this.visible2 = false
          this.confirmLoading = false;
        }
      } catch (error) {

      }
    },
    // 发布
    async publishBut(record) {
      try {
        const res = await api.SET_LIST_PUBLISH({ activityId: record.activityId, publishStatus: '1' });
        if (res.code === this.$SUCCESS_CODE) {
          this.getActiveData();
        }
      } catch (error) {
      }
    },
    // 取消发布
    async unPublishBut(record) {
      try {
        const res = await api.SET_LIST_PUBLISH({ activityId: record.activityId, publishStatus: '2' });
        if (res.code === this.$SUCCESS_CODE) {
          this.getActiveData();
        }
      } catch (error) {
        console.log(error);

      }
    },
    //下载文件
    async downFile(dataList, activityTitle, time) {
      if (this.exportType == 1) {
        let res = await api.GET_LIST_REGISTRANT({ activityIds: [dataList] })
        this.exportEntryVisible = false
        const blob = new Blob([res.data]);
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = objectUrl;
        a.setAttribute("download", "活动报名信息_" + activityTitle + '_' + time + ".xlsx");
        a.click();
      } else if (this.exportType == 2) {
        let res = await api.exportSignInBut({ periodIds: [dataList] })
        this.exportEntryVisible = false
        const blob = new Blob([res.data]);
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = objectUrl;
        a.setAttribute("download", activityTitle + '_' + time + ".xlsx");
        a.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  pointer-events: auto;
  min-width: 350px;
  display: inline-block;
  left: 50%;
  transform: translate(-50%);
}

.btn {
  padding: 0;
  margin-left: 5px;
}
</style>
